// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyAWUmTXTmP93S6lrSsDxRhHskWcECc0oBQ',
  authDomain: 'qr-system-2b59f.firebaseapp.com',
  databaseURL: 'https://qr-system-2b59f-default-rtdb.firebaseio.com',
  projectId: 'qr-system-2b59f',
  storageBucket: 'qr-system-2b59f.firebasestorage.app',
  messagingSenderId: '840964100591',
  appId: '1:840964100591:web:9c4a6ff3c356b4b65f661a',
  measurementId: 'G-TPSD605XBX',
};
// Initialize Firebase

const app = initializeApp(firebaseConfig);
// Export firestore database
// It will be imported into your react app whenever it is needed
export const db = getFirestore(app);
